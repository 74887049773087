.padding-b-30 {
    padding-bottom: 30px;
}

.padding-l-64 {
    padding-left: 64px;
}

.padding-l-32 {
    padding-left: 32px;
}

.padding-t-64 {
    padding-top: 64px;
}

.padding-b-40 {
    padding-bottom: 40px;
}

.padding-b-38 {
    padding-bottom: 38px;
}

.padding-t-84 {
    padding-top: 84px;
}

.padding-y-13 {
    padding-bottom: 13px;
    padding-top: 13px;
}

.padding-y-64 {
    padding-bottom: 64px;
    padding-top: 64px;
}

.padding-b-64 {
    padding-bottom: 64px;
}

.lineHeight26 {
    line-height: 26px;
}

.padding-top-32 {
    padding-top: 32px;
}

.height-601 {
    height: 601px;
}

.display-none {
    display: none;
}
.company-reg-fields-grid{
    display: grid;
    gap: 20px;
}
.padding-l-4{
    padding-left: 4px;
}
.text-error-600{
    color: rgb(220 38 38);
}
@media (min-width: 1666px) {
    .padding-l-80-3xl-i {
        padding-left: 80px !important;
    }
}
@media (min-width: 1260px) and (max-width: 1665px) {
    .padding-l-60-xl-i {
        padding-left: 60px !important;
    }
}

@media (min-width: 1024px) {
    .padding-t-64-lg {
        padding-top: 64px;
    }

    .display-block-lg {
        display: block;
    }

    .padding-l-64-lg {
        padding-left: 64px;

    }
    .padding-l-48-lg {
        padding-left: 48px;
    }
    .padding-r-38-lg {
        padding-right: 38px;
    }
    .company-registration-grid-lg {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 79px;
        align-items: start;
    }
}

@media (min-width: 768px) {
    .padding-t-84-md {
        padding-top: 84px;
    }
    .company-reg-fields-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}