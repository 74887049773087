.hero-slider-sm {
  height: 18rem !important;
}
.css-1f8sh1y {
  height: 18rem !important;
}

@media (min-width: 768px) {
  .hero-slider-md {
    height: 32rem !important;
  }
}

@media (min-width: 1260px) {
  .hero-slider-lg {
    height: 18rem !important;
  }
  .css-1f8sh1y {
    height: 13rem !important;
  }
}

@media (min-width: 1440px) {
  .hero-slider-2xl {
    height: 20rem !important;
  }
  .css-1f8sh1y {
    height: 20rem !important;
  }
}
