.padding-l-24 {
    padding-left: 24px;
}
.padding-b-24 {
    padding-bottom: 24px;
}

.padding-x-48-sm {
    padding-left: 48px;
    padding-right:48px;
}
.padding-y-18-sm {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}
.padding-x-32-sm {
    padding-left: 32px !important;
    padding-right:32px !important;
}
.padding-24-sm {
    padding-left: 24px !important;
    padding-right:24px !important;
    padding-top: 24px !important;
    padding-bottom:24px !important;
}
.bg-color {
    background-color: #E5E9EB;
}
.button-text:hover {
    background-color: #F4F4F4;
    
}
.border-none {
    border-radius: 0px !important;
}
.width-40 {
    width: 40%
}
.flex-1 {
    flex: 1 1 0%;
}
.content-div-padding-sm{
    padding-left: 20px;
    padding-top:144px;
}
.line-height {
    line-height: 3.25rem;
}
.margin-t-48 {
    margin-top: 48px;
}

@media (min-width: 768px) {
    .padding-l-16 {
        padding-left: 16px;
    }
    .padding-b-24 {
        padding-bottom: 24px;
    }
    .padding-x-md {
        padding-left: 40px;
        padding-right: 40px;
    }
} 

@media (max-width: 768px) {
    .padding-l-16 {
        padding-left: 16px;
    }
    .padding-b-24 {
        padding-bottom: 24px;
    }

} 

@media (min-width: 1024px) {
    .padding-l-0 {
        padding-left: 0px;
    }
    .padding-b-0 {
        padding-bottom: 0px;
    }
    .padding-l-lg-40 {
        padding-left: 40px;
    }
    .content-div-padding-lg{
        padding-top:100px;
        padding-bottom: 100px;
    }
} 
@media (min-width: 1260px) {
    .padding-l-lg-78 {
        padding-left: 40px;
    }
    .content-div-padding-xl{
        padding-top:100px;
        padding-bottom:100px;
    }
}

@media (min-width: 1440px) {
    .padding-l-lg-78 {
        padding-left: 78px;
    }
    .content-div-padding-2xl{
        padding-bottom:180px;
        padding-top:144px;
    }
    .width-2xl {
        width:56%;
    }
}


  