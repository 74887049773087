.toggle-container {
  width: 56px;
  height: 26px;
  background-color: #ccc;
  border-radius: 26px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button {
  width: 56px;
  height: 26px;
  border-radius: 26px;
  background-color: #ccc;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-button.off,
.toggle-button.on {
  background-color: #ccc; /* The gray color for "off" state */
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.75px;
  left: 4px;
  transition: left 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.toggle-market-text-left,
.toggle-market-text-right {
  position: absolute;
  top: 8px;
}

.toggle-market-text-right {
  left: 36px; /* Moves to the right when "on" */
}

.toggle-market-text-left {
  left: 8px; /* Moves to the left when "off" */
}
.toggle-button.on .toggle-circle {
  left: 33px; /* Moves to the right when "on" */
}

.toggle-button.off .toggle-circle {
  left: 3px; /* Moves to the left when "off" */
}

.toggle-market-text-left,
.toggle-market-text-right,
.toggle-market-text-circle {
  font-size: 10px; /* text-10 */
  font-weight: 600; /* font-semibold */
  color: #666666; /* text-[#666] */
  line-height: 10px;
}

.toggle-market-text-left:hover,
.toggle-market-text-right:hover,
.toggle-market-text-circle:hover {
  cursor: pointer; /* hover:cursor-pointer */
  color: #333333; /* hover:text-[#333] */
}

@media (min-width: 1536px) {
  /* 2xl breakpoint is typically 1536px and above in Tailwind */
  .toggle-market-text-left,
  .toggle-market-text-right,
  .toggle-market-text-circle {
    font-size: 12px; /* 2xl:text-14 */
    line-height: 12px;
  }
}
@media (min-width: 1024px) {
  .toggle-container {
    width: 65px;
    height: 30px;
    background-color: #ccc;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-button {
    width: 65px;
    height: 30px;
    border-radius: 30px;
    background-color: #ccc;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .toggle-circle {
    width: 23.5px;
    height: 23.5px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 4px;
    transition: left 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .toggle-market-text-left,
  .toggle-market-text-right {
    position: absolute;
    top: 10px;
  }

  .toggle-market-text-right {
    left: 40px; /* Moves to the right when "on" */
  }

  .toggle-market-text-left {
    left: 8px; /* Moves to the left when "off" */
  }
  .toggle-button.on .toggle-circle {
    left: 39px; /* Moves to the right when "on" */
  }

  .toggle-button.off .toggle-circle {
    left: 3px; /* Moves to the left when "off" */
  }
}
