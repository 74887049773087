@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/content-with-banner.css');
@import url('./components/slider.css');
@import url('./components/common.css');
@import url('./components/header.css');
@import url('./components/country-toggle.css');
@import url('./components/plp.css');
@import url('./components/hero.css');
@import url('./components/footer.css');
@import url('./components/product-section.css');
@import url('./components/segments-section.css');
@import url('./components/image-content-slider.css');
@import url('./components/brand.css');
@import url('./components/discover.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');
@import url('./components/product-tile.css');
@import url('./components/authentication.css');
@import url('./components/create-project.css');
@import url('./themes/default.css');

body {
  width: 100%;
  padding-top: 68px;
  overflow-x: hidden !important;
}
.header-less-container {
  margin-top: -68px;
  max-width: 70%;
  margin: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}
h1 {
  margin-bottom: 0rem !important;
}

button {
  cursor: default;
}

[type='checkbox']:checked:focus,
[type='checkbox']:checked:hover,
[type='radio']:checked:focus,
[type='radio']:checked:hover {
  background-color: currentColor !important;
  border-color: transparent !important;
}

[type='checkbox']:checked,
[type='radio']:checked {
  background-color: currentColor;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border-color: transparent;
}

.cursor-button {
  cursor: pointer !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}

ol {
  padding-left: 0px !important;
}
/* 
@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
} */

@media (min-width: 1030px) {
  body {
    padding-top: 100px;
  }
  .header-less-container {
    margin-top: -100px;
  }
}

@media (min-width: 1440px) {
  body {
    padding-top: 118px;
  }
  .header-less-container {
    margin-top: -118px;
  }
}

@media (min-width: 1666px) {
  body {
    padding-top: 136px;
  }
  .header-less-container {
    margin-top: -136px;
  }
}
@media (min-width: 1024px) and (max-width: 1165px) {
  .custom-text-css {
    font-size: 10px !important;
  }
}
.style-textarea {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #747474;
}

.style-textarea:focus {
  height: 100%;
  width: 100%;
  border-radius: 10px !important;
  --tw-ring-color: 1px solid #747474 !important;
  border-color: #747474 !important;
}

.bim-toggle {
  border: none !important;
  background-image: url('/images/bim-unchecked.svg') !important;
  background-position: center;
  background-color: white !important;
}

.bim-toggle:checked {
  right: 0;
  background-color: #ffffff !important;
  border: none !important;
  background-image: url('/images/bim-checked.svg') !important;
}

.product-summary {
  box-shadow: inset 0px 0px 200px 100px rgba(0, 0, 0, 0.4);
}

.bim-toggle:checked:hover {
  background-color: #ffffff !important;
  border: none !important;
}
.bim-toggle:checked + .toggle-label {
  background-color: #2b2c39;
}
.bim-toggle:checked:focus,
.bim-toggle:checked:hover {
  background-color: #ffffff !important;
  border: none !important;
}

.account-dropdown {
  font-weight: normal;
}
.hor-rule{
  border-bottom: 1px solid #666666;
  margin-bottom: 27px;
}
.hor-rule-hr{
  border-top: 1px solid #666666;
  margin-top: 18px; 
  margin-bottom: 27px;
}
.attribute-break-h{
  border-top: 1px solid #666666;
  margin-top: 0px;
  margin-bottom: 22px;
}
.hor-rule-rrp{
  border-top: 1px solid #666666;
  margin-top: 2px;
  margin-bottom: 2px;
}

@media print {
  .product-features-n-benefits ul {
    font-size: 12px;
  }
  body{
    padding: 20px;
  }
.hor-rule{
  margin-bottom: 4px;
}
.hor-rule-hr{
  margin-top: 2px;
  margin-bottom: 2px;
}
.hor-rule-rrp{
  margin-top: 1px;
  margin-bottom: 1px;
}
.attribute-break-h{
  border-top: 1px solid #666666;
  margin-top: 0px;
  margin-bottom: 0px;
}
.summary-head-left{
  width: 50%;
  min-width: 50%;
  }
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

