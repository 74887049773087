.column-with-separator::after{
    content: "|";
    color: #ffffff; 
    padding-right: 11px; 
    padding-left: 11px;
}

.column-with-separator:first-child::before{
    content: "";
}

.column-border{
    width:100%;
    border-bottom: 1px solid #FFFFFF40;
    text-align: center !important;
}
.padding-y-40 {
    padding-top:40px;
    padding-bottom:40px;
}
.paddinng-x-24 {
    padding-left: 24px;
    padding-right: 24px;
}
.padding-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}
.margin-b-10 {
    margin-bottom: 10px;
}
.padding-b-10 {
    padding-bottom: 10px;
}
.padding-right-19 {
    padding-right: 19px;
}
.margin-t-32 {
    margin-top : 32px;
}
/* FooterBottom.css */

.pt-5 {
    padding-top: 3rem; /* Adjust as needed */
}

.mt-4 {
    margin-top: 1.5rem; /* Adjust as needed */
}

.pb-3 {
    padding-bottom: 1rem; /* Adjust as needed */
}
.marign-bottom-0 {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .padding-y-md-32 {
        padding-bottom: 32px;
        padding-top: 32px;
    }
    .padding-x-md-28 {
        padding-right: 28px;
        padding-left: 28px;
    }
    .padding-t-md-32 {
        padding-top : 32px;
    }
    .flex-md {
        display : flex
    }
} 
@media (min-width: 1024px) {
    .padding-x-md-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .flex-lg {
        display: flex !important;
    }
}

@media (min-width: 1260px) {
    .padding-x-lg-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .padding-t-lg-28 {
        padding-top: 28px;
    }
    .flex-lg {
        display: flex !important;
    }
    
}

@media (min-width: 1440px) {
    .padding-x-2xl-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .padding-t-2xl-48 {
        padding-top:48px;
    }
    .flex-lg {
        display: flex !important;
    }
    .margin-t-2xl-20 {
        margin-top: 20px;
    }
}
