/* Media Queries */

.no-result-container {
  margin-bottom: 12rem;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #333;
}
.search-header-container {
  margin-top: 1.5rem;
  padding-left: 80px;
  padding-right: 80px;
}

.results-zero .no-result-container {
  margin-bottom: 2.5rem;
}
.plp-title {
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-top: 5rem;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  line-height: 2rem;
  color: #090909;
}
.brand-title {
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 5rem;
  text-align: left;
  font-size: 48px;
  font-weight: 500;
  line-height: 2rem;
  color: #090909;
}
.current-refinement-container {
  margin-top: 8px; /* 32px */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 3rem; /* 192px */
  padding-left: 80px; /* 96px */
  padding-right: 80px; /* 96px */
}
.result-count {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-top: 24px;
}
@media (max-width: 1440px) {
  .search-header-container {
    margin-top: 2rem;
    padding-left: 60px;
    padding-right: 60px;
  }
  .plp-title {
    padding-bottom: 2rem;
    padding-top: 3rem;
    font-size: 40px;
  }
  .brand-title {
    padding-bottom: 2rem;
    padding-top: 3rem;
    font-size: 40px;
  }
  .current-refinement-container {
    padding-left: 60px; /* 33.75px */
    padding-right: 60px; /* 320px */
  }
  .result-count {
    gap: 48px;
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1279px) {
  .search-header-container {
    padding-left: 48px;
    padding-right: 48px;
  }
  .current-refinement-container {
    padding-left: 48px; /* 240px */
    padding-right: 48px; /* 240px */
  }
  .result-count {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 1023px) {
  .no-result-container {
    margin-top: 5rem;
  }
  .search-header-container {
    margin-top: 0.75rem;
    padding-left: 24px;
    padding-right: 24px;
  }
  .plp-title {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .brand-title {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 0rem;
  }
  .current-refinement-container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .result-count {
    padding-left: 48px;
    padding-right: 48px;
    flex-direction: row;
  }
}
@media (min-width: 1600px) {

  .current-refinement-container {
    padding-right: 63px;
  }

}
@media (min-width: 1680px) {

  .current-refinement-container {
    padding-right: 80px;
  }

}
