.tabs-container {
  padding: 10px;
}

.tabs-container.desktop {
  display: flex;
  flex-direction: column;
}

.tab-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabs-container.mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-item {
  display: flex;
  align-items: center;
}

.tab {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  transition: all 0.3s ease;
  gap: 10px;
  position: relative;
}


.tab.active {
  font-weight: bold;
  color: black;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.circle.active-circle {
  background-color: black;
}

.line {
  height: 2px;
  background-color: #ddd;
  flex: 1;
}

.line-parent {
  height: 2px;
  background-color: #ddd;
  flex: 1;
}

.horizontal-line {
  width: 100px;
  height: 2px;
  margin-left: 18px;
  background-color: #ddd;
}

.vertical-line {
  width: 2px;
  height: 20px;
  margin-left: 18px;
  background-color: #ddd;
}

.vertical-line-active {
  width: 2px;
  height: auto;
  margin-left: 18px;
  background-color: #ddd;
}

.tab-content {
  padding: 10px;
  margin-top: 20px;
}

/* project details*/
.about-project-fields-grid {
  display: grid;
  gap: 20px;
}

.projectDropDown span {
  font-weight: 500 !important;
}

.projectDropDown ul {
  width: 100% !important;
}
.copy-share-link{
  color: #494949;
  font-size: 14px;
}
.clipboard-copy{
  width: 20px;
  height: 20px;
  inset: 0;
  margin-right: 20px !important;
  margin: auto;
  z-index: 20;
  cursor: pointer;
}
@media (min-width: 1280px) {
  .projectDropDown ul {
    top: 27px !important;
  }
}

@media (min-width:744px) and (max-width: 1023px) {
  .aboutProject {
    display: flex;
    gap: 4;
  }

  .aboutProjectFlex {
    flex: 1;
    text-align: center;
  }
}
.project-list {
  .tabs-header {
    display: flex;
  }

  .tab-button {
    padding: 10px 20px;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    width: 50%;
  }

  .tab-button.active {
    font-weight: bold;
  }

  .tab-button.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #333333;
  }

}

@media (min-width:1666px) {
  .sidemenu-container {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}