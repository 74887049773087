/* Base Styles */
.menu-dropdown-wrapper {
  transition: all 300ms ease-in-out;
  position: absolute;
  height: 82vh;
  overflow: hidden !important;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  background-color: #f3ede9;
  padding: 24px 24px 40px 24px;
}

.menu-dropdown-wrapper.show {
  opacity: 1;
  z-index: 20;
  transition-delay: 300ms;
}

.menu-dropdown-wrapper.hide {
  opacity: 0;
  z-index: -999;
  pointer-events: none;
}

.menu-dropdown-wrapper.large {
  min-height: 30rem;
  padding: 3.75rem 5rem;
}

.menu-link-container {
  gap: 5rem;
}

.menu-section-title {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.markdown-custom p strong {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}

.menu-tile-solutions .markdown-custom p strong,
.markdown-custom p a strong {
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
  white-space: nowrap;
}

.markdown-custom > img {
  margin-top: 1rem;
}

.menu-tile-solutions:last-child div > img {
  min-width: 18rem;
  margin-top: 1.5rem;
}

.menu-tile-solutions:last-child div > img + p {
  margin: 1rem 0;
  color: #090909;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.menu-tile-solutions:last-child div > img + p + img {
  min-width: 18px;
  height: 18px;
  margin-top: 0.5rem;
}

.menu-tile-solutions .col:last-child {
  position: absolute;
  top: 90%;
}

.markdown-custom > p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 12px;
}

.menu-tile > div > p {
  font-size: 12px;
  margin-top: 8px;
  font-weight: 400;
  color: #000000;
}

.sector-menu-tile div img,
.tools-menu-tile div img {
  width: 120px !important;
  height: 80px !important;
}

.about-menu-tile div img {
  width: 175px !important;
  height: 175px !important;
}

.tools-menu-tile .markdown-custom,
.sector-menu-tile .markdown-custom,
.about-menu-tile .markdown-custom {
  width: fit-content;
}

.sector-menu-tile .markdown-custom,
.tools-menu-tile .markdown-custom {
  width: 8rem;
}

.sector-menu-tile div p,
.about-menu-tile div p,
.tools-menu-tile div p {
  max-width: 85%;
  margin-top: 8px;
}

.markdown-custom > span {
  position: relative;
  left: 2rem;
}

.markdown-custom:last-child span {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 16px;
}

.markdown-custom:last-child span p {
  white-space: nowrap;
}

.markdown-custom > span > p {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  color: #000000;
  margin-top: 2px;
}
.markdown-custom > p > img {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
/* Button Styling */
.menu-button {
  margin-top: 1.625rem;
  padding: 0.75rem;
  background-color: #f4f4f4;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.menu-button:hover {
  background-color: #e0e0e0;
}

.menu-button span {
  font-size: 0.875rem;
  font-weight: 500;
}

.opacity-0 {
  opacity: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.header-list-container {
  padding-left: 24px;
}

.menu-drawer-mobile {
  width: 90%;
  border: 1px solid #9ca3af;
  background-color: #f3f4f6;
}
.header-list-container {
  display: none; /* Hidden for screens <= 1023px */
}
.markdown-custom-button > span {
  display: none;
}
.mobile-menu-btn {
  padding: 8px 20px 8px 16px; /* px-16 */

  text-align: left; /* text-left */
  display: flex;
  gap: 10px; /* gap-10 */
}
.mobile-menu-btn > p {
  font-size: 20px; /* text-20 */
  font-weight: 600; /* font-semibold */
  color: #333333; /* text-[#090909] */
}
.markdown-custom > p > strong,
.markdown-custom-button > p > strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
}
.markdown-custom > p > strong,
.markdown-custom > p > a > strong,
.markdown-custom > p > img,
.markdown-custom-button > img,
#headlessui-disclosure-panel-\:R29latempmdkq\: > div:nth-child(7) > a,
#headlessui-disclosure-panel-\:ri9\: > div:nth-child(7) > a {
  display: none;
}

.solutions-img {
  padding: 0px 16px;
  margin-top: 24px;
}
.solutions-img p {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #090909;
  margin-top: 16px;
}

.markdown-custom > img {
  min-width: 100%;
  margin-top: 1rem;
}
.markdown-custom:last-child span {
  display: flex;
  flex-direction: column;
}
.markdown-custom > p > img,
.markdown-custom-button > p > img {
  height: 2.25rem !important; /* 36px converted to rem */
  width: 2.25rem !important; /* 36px converted to rem */
}

.markdown-custom > p,
.markdown-custom-button > p {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* 8px converted to rem */
  font-size: 16px;
  font-weight: 500;
  margin-left: 8px;
}
.markdown-custom > span {
  position: relative;
  left: 2rem;
}
.menu-tile > div > p {
  font-size: 14px;
  margin-top: 8px;
  font-weight: 400;
  color: #000000;
}
.menu-tile > div > img {
  width: 100%;
}

.markdown-custom > span {
  position: relative;
  left: 4.25rem;
}

.markdown-custom > span > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  color: #000000;
}

.markdown-custom-button > p > a > strong,
.markdown-custom-button > p > strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
}
.markdown-custom-button > p > a {
  pointer-events: none;
}
.markdown-custom > span > p:last-child {
  text-decoration: underline;
}
@media (min-width: 1024px) {
  .header-list-container {
    display: flex;
    align-items: center;
    padding-left: 48px;
  }
  /* Base Styles */
  .menu-dropdown-wrapper {
    transition: all 300ms ease-in-out;
    position: absolute;
    overflow-y: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    background-color: #f3ede9;
    padding: 24px 24px 40px 24px;
  }
  .markdown-custom p strong,
  .markdown-custom p img,
  .markdown-custom-button img {
    display: block;
  }
  .menu-dropdown-wrapper.show {
    opacity: 1;
    z-index: 20;
    transition-delay: 300ms;
  }
  .markdown-custom p,
  .markdown-custom-button p {
    margin-left: 0px;
  }
  .link-inline-block {
    display: inline-block; /* Make the link only as wide as its content */
    margin-right: auto; /* Push remaining space to the right of the content */
  }

  .col {
    display: flex; /* Ensure that the grid items can expand to fill their container */
    align-items: flex-start; /* Align the content at the start of each grid cell */
  }
  .menu-dropdown-wrapper.hide {
    opacity: 0;
    z-index: -999;
    pointer-events: none;
  }

  .menu-dropdown-wrapper.large {
    min-height: 30rem;
    padding: 3.75rem 5rem;
  }

  .menu-link-container {
    gap: 5rem;
  }

  .menu-section-title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  .markdown-custom p strong {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
  }

  .menu-tile-solutions .markdown-custom p strong,
  .markdown-custom p a strong {
    font-size: 12px;
    font-weight: 500;
    line-height: 32px;
    color: #000000;
    white-space: nowrap;
  }

  .markdown-custom > img {
    margin-top: 1rem;
  }

  .menu-tile-solutions:last-child div > img {
    min-width: 18rem;
    margin-top: 1.5rem;
  }

  .menu-tile-solutions:last-child div > img + p {
    margin: 1rem 0;
    color: #090909;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .menu-tile-solutions:last-child div > img + p + img {
    min-width: 18px;
    height: 18px;
    margin-top: 0.5rem;
  }

  .menu-tile-solutions .col:last-child {
    position: absolute;
    top: 90%;
  }

  .markdown-custom p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 3px;
    font-size: 12px;
  }

  .menu-tile > div > p {
    font-size: 12px;
    margin-top: 8px;
    font-weight: 400;
    color: #000000;
  }

  /* .sector-menu-tile div img,
  .tools-menu-tile div img {
    width: 120px !important;
    height: 80px !important;
  } */

  .about-menu-tile div img {
    width: 175px !important;
    height: 175px !important;
  }

  .tools-menu-tile .markdown-custom,
  .sector-menu-tile .markdown-custom,
  .about-menu-tile .markdown-custom {
    width: fit-content;
  }
  /* 
  .sector-menu-tile > .markdown-custom,
  .tools-menu-tile > .markdown-custom {
    width: 8rem;
  } */

  .sector-menu-tile div p,
  .about-menu-tile div p,
  .tools-menu-tile div p {
    max-width: 85%;
    margin-top: 8px;
  }

  .markdown-custom > span {
    position: relative;
    left: 2rem;
  }
  .markdown-custom > span > p:last-child {
    display: none;
  }

  .markdown-custom:last-child span {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 16px;
  }

  .markdown-custom:last-child span p {
    white-space: nowrap;
  }

  .markdown-custom span p {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    color: #000000;
    margin-top: 2px;
  }
  .markdown-custom p img {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  /* Button Styling */
  .menu-button {
    margin-top: 1.625rem;
    padding: 0.75rem;
    background-color: #f4f4f4;
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .menu-button:hover {
    background-color: #e0e0e0;
  }

  .menu-button span {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .opacity-0 {
    opacity: 0;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .menu-drawer-mobile {
    width: 90%;
    border: 1px solid #9ca3af;
    background-color: #f3f4f6;
  }

  .menu-section-title {
    font-size: 14px;
  }

  .menu-dropdown-wrapper {
    padding: 24px 48px 48px 48px;
  }

  .markdown-custom p strong,
  .markdown-custom p a strong {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #000000;
    display: block;
  }

  .markdown-custom > span {
    left: 2.25rem;
  }

  .markdown-custom > span > p {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    color: #000000;
  }

  .menu-tile-solutions:last-child div > img {
    min-width: 22rem;
  }

  .menu-tile-solutions:last-child div > img + p {
    font-size: 14px;
    line-height: 16px;
  }

  .sector-menu-tile .markdown-custom,
  .tools-menu-tile .markdown-custom {
    width: 9rem;
  }

  .sector-menu-tile div img,
  .tools-menu-tile div img {
    width: 142px !important;
    height: 90px !important;
  }

  .about-menu-tile div img {
    width: 175px !important;
    height: 175px !important;
  }

  .menu-tile-solutions:last-child div > img + p + img {
    width: 18px;
    height: 18px;
  }

  .markdown-custom > img {
    height: 6.25rem;
    width: 9.5rem;
  }
}

@media (min-width: 1280px) {
  .header-list-container {
    padding-left: 60px;
  }

  .menu-section-title {
    font-size: 14px;
  }

  .menu-dropdown-wrapper {
    padding: 24px 60px 48px 60px;
  }

  /* .sector-menu-tile > .markdown-custom,
  .tools-menu-tile > .markdown-custom {
    width: 10rem;
  } */
}

@media (min-width: 1367px) {
  .sector-menu-tile .markdown-custom,
  .tools-menu-tile .markdown-custom {
    width: 10rem;
  }
}

@media (min-width: 1536px) {
  .header-list-container {
    padding-left: 80px;
  }

  .menu-section-title {
    font-size: 14px;
  }

  .menu-dropdown-wrapper {
    padding: 24px 80px 40px 60px;
  }

  .markdown-custom p strong {
    font-size: 12px;
  }

  .markdown-custom > p > img {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .markdown-custom > span {
    left: 2.75rem;
  }

  .markdown-custom > span > p {
    font-size: 11px;
  }

  .menu-tile-solutions:last-child div > img {
    min-width: 22rem;
  }

  .sector-menu-tile div img,
  .tools-menu-tile div img {
    width: 200px !important;
    height: 132px !important;
  }
  .sector-menu-tile .markdown-custom,
  .tools-menu-tile .markdown-custom {
    width: 12rem;
  }
  .about-menu-tile div img {
    width: 200px !important;
    height: 200px !important;
  }

}
@media (min-width: 1440px) {
  .header-list-container {
    padding-left: 60px;
  }
}
@media (min-width: 1666px) {
  .header-list-container {
    padding-left: 80px;
  }
}
@media (min-width: 1666px) {
  .menu-dropdown-wrapper {
    padding: 48px 80px 80px 80px;
  }

  .menu-tile-solutions:last-child div > img {
    min-width: 25rem;
  }

  .markdown-custom p strong {
    font-size: 16px;
  }

  .markdown-custom > span > p {
    font-size: 12px;
  }

  .menu-tile-solutions .markdown-custom p strong,
  .markdown-custom p a strong {
    font-size: 14px;
  }

  .sector-menu-tile .markdown-custom,
  .tools-menu-tile .markdown-custom {
    width: 12rem;
  }
  .menu-section-title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
  }
  .markdown-custom p strong {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    color: #000000;
  }
  .menu-tile-solutions .markdown-custom p strong,
  .markdown-custom p a strong {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    color: #000000;
    white-space: nowrap;
  }
  .menu-tile-solutions:last-child div > img + p + img {
    min-width: 32px;
    height: 32px;
    margin-top: 1rem;
  }
  .menu-tile-solutions:last-child div > img + p {
    margin: 1rem 0;
    color: #090909;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1920px) {
  .menu-dropdown-wrapper {
    padding: 48px 80px 80px 80px;
  }
}

@media (min-width: 1440px) {
  .custom-title-fix {
    position: relative;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1024px) {
  .support-menu img{
      height: 90px !important;
      width: 142px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1535px) {
  .support-menu img{
      height: 210px !important;
      width: 230px !important;
  }
}

@media (min-width: 1536px) and (max-width: 2100px) {
  .support-menu img{
      height: 260px !important;
      width: 280px !important;
  }
}

@media (min-width: 1600px) {
  .custom-title-fix {
    position: relative;
    margin-bottom: 2rem;
  }
}
@media (width: 1024px) {
  .menu-dropdown-wrapper {
    height: 40vh;
  }

 

}

@media (width: 1440px) {
  .menu-dropdown-wrapper {
    height: 70vh;
  }

}
@media (width: 1400px) {
  .menu-dropdown-wrapper {
    height: 60vh;
  }
}

@media (min-width: 1700px) {
  .menu-tile-solutions:last-child div > img {
    min-width: 32rem;
    margin-top: 1.5rem;
  }
  .about-menu-tile div img {
    /* width: 100%; */
    width: 340px !important;
    height: 340px !important;
  }
  .sector-menu-tile .markdown-custom,
  .tools-menu-tile .markdown-custom {
    width: 14rem;
  }
  .markdown-custom span p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }

  .markdown-custom p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 16px;
    margin-top: 8px;
  }
}

.search-view {
  padding-right: 24px;

  @media (max-width: 412px) {
    padding-right: 10px;
  }

  @media (max-width: 320px) {
    padding-right: 10px;
  }
}

.search-view-user {
  @media (max-width: 1023px) {
    padding-left: 24px !important;
  }
  @media (max-width: 412px) {
    padding-left: 10px !important;
  }

  @media (max-width: 320px) {
    padding-left: 10px !important;
  }
}
