.card {
    border: none !important;
    border-radius: 0px !important;
  }
  
  .card-description {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .card:hover .card-description {
    opacity: 1;
    visibility: visible;
  }

  .font-weight-600 {
    font-weight:600;
  }
.hover-bg:hover {
  background-color: #F3EDE9 ;
}
.padding-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.padding-l-24 {
  padding-left: 24px;
}