.w-min-100-41 {
  width: min(100%, 41.8rem);
}
.content-markdown-container {
  color: #333333;
}
.content-markdown-container > p {
  padding-top: 12px;
}
.content-markdown-container > figure > div > figcaption {
  text-align: left !important;
}
.content-markdown-container > p {
  width: min(100%, 41.8rem);
  margin: auto;
}

.content-markdown-container > ul {
  width: min(100%, 41.8rem);
  margin: auto;
  list-style-type: disc;
}

.content-markdown-container > ul > li {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.content-markdown-container > ol {
  width: min(100%, 41.8rem);
  margin: auto;
}

.content-markdown-container > figure > div > figcaption {
  width: min(100%, 41.8rem);
  margin: auto;
}

.content-markdown-container > p > a,
.content-markdown-container > ul > li > p > a,
.content-markdown-container > a {
  color: #337ab7 !important;
}
.max-w-675 {
  max-width: 675px;
}
.padding-t-128 {
  padding-top: 128px;
}

.top-33-per {
  top: 33.33%;
}
.left-15-per {
  left: 15%;
}

.translate-negative-y-50 {
  transform: translateY(-50%);
}
.section-w-banner-cta{
    border-color: #2B2C39;
}
.section-w-banner-cta::hover{
    border-color: #F4F4F4 !important;
    background: #F4F4F4 !important;
}
.section-w-banner-cta > .cta-text{
    color: #090909;
}
@media (min-width: 1024px) {
  .w-32-lg {
    width: 32rem;
  }
  .margin-t-120-lg {
    margin-top: 120px;
  }
}
