.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-icon-position {
  top: calc(-25vh + 16px) !important; /* Adjusts based on viewport height (1% of viewport height + 16px) */
  right: calc(-25vw + 16px) !important; /* Adjusts based on viewport width (1% of viewport width + 16px) */
  height: 48px !important;
  width: 48px !important;
  color: #eee !important;
}
