.min-h {
    min-height: 40px;
}
.padding-b-10 {
    padding-bottom: 10px;
}
.padding-y-sm-48 {
    padding-top: 48px;
    padding-bottom: 48px;
}
.padding-l-sm-24 {
    padding-left: 24px;
}
.margin-b-sm-36 {
    margin-bottom: 36px;
}
.margin-b-3 {
    margin-bottom: 3px;
}
.margin-b-4 {
    margin-bottom: 4px;
}
.slide:hover {
    background-color: "#F3EDE9";
}
.margin-t-16 {
    margin-top: 16px;
}

.padding-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}


@media (min-width: 768px) {
    .padding-b-md-28 {
        padding-bottom: 28px;
    }
    .padding-t-md-48 {
        padding-top: 48px;
    }
    .padding-l-md-28 {
        padding-left: 28px;
    }
    .margin-b-md-56 {
        margin-bottom: 56px;
    }
} 

@media (min-width: 1024px) {
    .padding-l-lg-48 {
        padding-left: 48px;
    }
    .padding-b-lg-48 {
        padding-bottom: 48px;
    }
    .padding-t-lg-48 {
        padding-top: 48px;
    }
    /* .padding-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    } */
} 

@media (min-width: 1120px) {
    .padding-l-48 {
        padding-left: 48px !important;
        padding-right: 48px !important;
    }
} 

@media (min-width: 1260px) {
    .padding-x-xl-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .padding-b-xl-60 {
        padding-bottom: 60px;
    }
    .padding-t-xl-60 {
        padding-top: 60px;
    }
    .margin-b {
        padding-bottom: 1.2rem !important;
    }
    .padding-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

@media (min-width: 1440px) {
    .padding-x-2xl-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .padding-b-2xl-80 {
        padding-bottom: 80px;
    }
    .padding-t-2xl-80 {
        padding-top: 80px;
    }
    .padding-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (min-width: 1400px) {
    .margin-b-xl {
        padding-bottom: 1.8rem !important;
    }
    .padding-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 1666px) {
    .padding-x-3xl-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 1610px) {
    .margin-b-2xl {
        padding-bottom: 2rem;
    }
}

