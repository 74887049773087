.fontSize18-sm{
  font-size: 18px;

}
.fontSize16-sm {
  font-size: 16px;
}
.font-size12-sm {
  font-size: 12px;
}
.fontSize14-sm {
  font-size: 14px;
}
.container-product {
  max-width: 1520px;
  margin: 0 auto;
}
.fontSize34-sm {
  font-size: 34px;
}
.fontSize32-sm {
  font-size: 32px;
}
.font-size16-sm {
  font-size: 16px;
}
.font-size22-sm {
  font-size: 22px;
}
.font-size24-sm {
  font-size: 24px;
}
.lineHeight40 {
  line-height: 2.5rem;
}
.lineHeight6 {
  line-height: 1.5rem;
}
.lineHeight48 {
  line-height: 3rem;
}
.lineHeight32 {
  line-height: 2rem;
}

.margin22-sm {
  margin-top: 22px;
}
.margin-y-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.padding-t-32 {
  padding-top: 32px;
}
.padding-t-40 {
  padding-top: 40px;
}
.padding-b-80 {
  padding-bottom: 80px;
}
.padding-b-48 {
  padding-bottom: 48px;
}
.padding-b-12 {
  padding-bottom: 12px;
}
.padding-x-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.padding-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.padding-y-18 {
  padding-left: 18px;
  padding-right: 18px;
}

.height23-rem {
  height: 23rem;
}
.padding-32{
  padding: 32px;
}
ul {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
p {
  margin-bottom: 0 !important;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}
.cursor-p {
  cursor: pointer !important;
}
.border-dim-gray {
  border: 1px solid #747474 !important;
}
.border-light-gray{
  border: 1px solid #A7A7A7 !important;

}

.border-dim-gray {
  border: 1px solid #747474 !important;
}
.border-dim-gray:focus-visible {
  border: 1px solid #747474 !important;
}
.border-dim-gray:focus {
  border: 1px solid #747474 !important;
}
.border-w-1 {
  border-width: 1px;
}
@media (min-width: 1024px) {
  .font-size20-lg {
    font-size: 20px;
  }
  .border-none-lg {
    border: 0;
  }
  .height32-rem-lg {
    height: 32rem;
  }
  .padding-x-0-lg {
    padding-left: 0;
    padding-right: 0;
  }
  .padding-x-lg-60{
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 768px) {
  .fontSize32-md {
    font-size: 32px;
  }
  .fontSize18-md {
    font-size: 18px;
  }
  .fontSize16-md {
    font-size: 16px;
  }
  .block-md {
    display: block;
  }
  .padding-t-40-md {
    padding-top: 40px;
  }
}

@media (min-width: 1260px) {
  .fontSize16-xl {
    font-size: 16px;
  }
  .fontSize14-xl {
    font-size: 14px;
  }
  .fontSize30-xl {
    font-size: 30px;
  }

  .fontSize48-xl {
    font-size: 3rem;
  }
  .lineHeight-xl {
    line-height: 3rem;
  }
  .fontSize36-xl {
    font-size: 2.25rem;
  }
  .fontSize32-xl {
    font-size: 2.25rem;
  }
  .fontSize18-xl {
    font-size: 18px;
  }
  .width-xl {
    width: 78%;
  }
  .padding-t-xl-68 {
    padding-top: 68px;
  }
}

@media (min-width: 1440px) {
  .fontSize14-2xl {
    font-size: 14px;
  }
  .fontSize18-2xl {
    font-size: 18px;
  }
  .fontSize20-2xl {
    font-size: 20px;
  }
  .fontSize62-2xl {
    font-size: 62px;
  }
  .fontSize40-2xl {
    font-size: 40px;
  }
  .fontSize48-2xl {
    font-size: 48px;
  }
  .lineHeight-2xl {
    line-height: 5rem;
  }
  .fontSize22-2xl {
    font-size: 22px;
  }
  .fontSize32-2xl {
    font-size: 32px;
  }
  .padding-y-2xl-80 {
    padding-bottom: 80px !important;
    padding-top: 80px !important;
  }
  .padding-x-2xl-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .padding-t-2xl-80 {
    padding-top: 80px;
  }
  .padding-t-2xl-68 {
    padding-top: 68px;
  }
}
@media (min-width: 1666px) {
  .height40-rem-3xl {
    height: 40rem;
  }
  .padding-x-3xl-80{
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

@media (min-width: 360px) {
  .image-content-min-height {
    min-height: 170px !important;
  }
}

@media (min-width: 500px) {
  .image-content-min-height {
    min-height: 100px !important;
  }
}

@media (max-width: 640px){
  .w-full-sm{
    width: 100%;
  }
  .padding-b-0-sm{
    padding-bottom: 24px;
  }
}