.discover-section {
    background-color: #2B2C39;
}
.padding-y-48 {
    padding-top: 48px;
    padding-bottom: 48px;
}
.padding-l-24 {
    padding-left: 24px;
}
.margin-b-sm-36 {
    margin-bottom: 36px;
}
.margin-b-sm-24 {
    margin-bottom: 24px;
}
.margin-t-sm-12 {
    margin-top: 12px;
}
.min-h-35 {
    min-height: 35px;
}
.discover-bg:hover {
    background-color: #fafdf3 !important;
}

@media (min-width: 768px) {
    .padding-l-md-28 {
        padding-left: 28px;
    }
    .margin-b-md-48 {
        margin-bottom: 48px;
    }
} 

@media (min-width: 1024px) {
    .padding-x-lg-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .padding-y-lg-48 {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    
} 

@media (min-width: 1260px) {
    .padding-x-xl-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .padding-y-xl-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    
}

@media (min-width: 1440px) {
    .padding-x-2xl-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .padding-y-2xl-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}