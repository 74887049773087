.padding-t-sm-48 {
    padding-top: 48px;
}
.padding-l-sm-24 {
    padding-left: 24px;
}


.height-48 {
  height: 48px !important;
  width: 48px !important;
}
.line-height-28 {
    line-height: 28px;
}
.bd-r {
    border-radius: 12px;
}
.custom-hover:hover {
  background-color: #E5E9EB;
  border-radius: 12px;
}



@media (min-width: 800px) {
    .padding-t-md-48 {
        padding-top: 48px;
    }
    .padding-b-md-28 {
        padding-bottom: 28px;
    }
    .padding-l-md-28 {
        padding-left: 28px;
    }
    .margin-t-md-6 {
        margin-top: 6px !important;
    }
    /* .backg-color {
        background-color: #E5E9EB;
    } */

} 

@media (min-width: 1024px) {
    .padding-x-lg-48 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .height-80 {
      height: 80px !important;
      width: 80px !important;
  }
  .photos-grid-container {
    gap: 68px !important;
  }
  .sub {
    column-gap:68px !important;
  }
    
} 
@media (min-width: 1260px) {
    .padding-b-xl-60 {
        padding-bottom: 60px;
    }
    .padding-x-xl-60 {
        padding-left: 60px;
        padding-right: 60px;
    }
    .padding-t-xl-60 {
        padding-top: 60px;
    }
    .margin-t-xl-8 {
        margin-top: 8px !important;
    }
    .height-80 {
      height: 80px !important;
  }
    
    
}

@media (min-width: 1440px) {
    .padding-x-2xl-80 {
        padding-left: 80px;
        padding-right: 80px;
    }
    .padding-t-2xl-80 {
        padding-top: 80px !important;
    }
    .height-80 {
      height: 80px !important;
  }
}

.min-h {
    min-height: 40px;
}


html,
body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 1.2rem;
}


.photos-grid-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  align-items: start;
  gap: 84px;

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }

  .img-box {
    border: 1px solid #ffffff;
    position: relative;
  }

  .img-box:hover .transparent-box {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .img-box:hover .caption {
    transform: translateY(-5px);
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  .caption {
    color: white;
    transition: transform 0.3s ease, opacity 0.3s ease;
    font-size: 1.5rem;
  }

  .transparent-box {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-photo {
    grid-row: 1;
    grid-column: 1;
  }

  .sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0em;
    column-gap:84px;
    row-gap: 16px;

    &:nth-child(0) {
      grid-column: 1;
      grid-row: 1;
    }

    &:nth-child(1) {
      grid-column: 2;
      grid-row: 1;
    }

    &:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }

    &:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
    }
  }
}


.hide-element {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.container-segment {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
}