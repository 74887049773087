.padding-t-13 {
  padding-top: 13px;
}
.padding-b-13 {
  padding-bottom: 13px;
}
.padding-y-9 {
  padding-bottom: 9px;
  padding-top: 9px;
}
.padding-x-17 {
  padding-left: 17px;
  padding-right: 17px;
}
.padding-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.margin-t-8 {
  margin-top: 8px;
}
.bottom-26-sm {
  bottom: 26px;
}
.height-13rem {
  height: 13rem;
}
.left-12-sm {
  left: 12px;
}
.gap-10-sm {
  gap: 10px;
}
.gap-24-sm{
  gap: 24px;

}
@media (min-width: 1440px) {
  .bottom-20-2xl {
    bottom: 20px;
  }
  .height-14rem-2xl {
    height: 14rem !important;
  }
}

@media (min-width: 744px) {
  .left-24-md {
    left: 24px;
  }
}
